<template>
  <div>
  	<damo-header/>
		<div class="banner-box" style="height: 300px;">
			<div class="box">
				<div class="about-banner">
					<p class="p01 fw">达摩持续用新技术赋能商家</p>
					<p class="p02" @click="handlenav('https://www.lagou.com/gongsi/j142419.html')">加入我们</p>
				</div>
			</div>
			<div class="banner-con">
				<img src="../static/images/banner_05.png">
			</div>
		</div>
		<div class="about-box">
			<div class="box section">
				<div class="about-title fw">了解达摩</div>
				<div class="about-box-a">
					<div class="diva">
						<img class="img-a" src="../static/images/icon01.png" alt="">
						<div class="con-a fl">
							<p class="p01">达摩初心</p>
							<p class="p02">让我们的用户更好的经营会员</p>
						</div>
					</div>
					<div class="divb">
						<img class="img-a" src="../static/images/icon02.png" alt="">
						<div class="con-a fl">
							<p class="p01">达摩使命</p>
							<p class="p02">让零售更美好</p>
						</div>
					</div>
					<div class="divc">
						<img class="img-a" src="../static/images/icon03.png" alt="">
						<div class="con-a fl" style="margin-top:-6px">
							<p class="p01">达摩愿景</p>
							<p class="p02">成为零售行业最大的普惠智能运营服务商</p>
						</div>
					</div>
				</div>
			</div>

      <div class="about-box-b section">
        <div class="about-conl">
          <div class="title">达摩简介</div>
          <div class="text">
			  浙江达摩网络科技有限公司，成立于2015年。坐落于杭州未来科技城CBD核心区金融港，超甲LEED白金写字楼。<br>
			  我们是一家全球领先的用户资产管理运营、SCRM软件开发及应用于一体的数字化服务公司。为鞋服、箱包、配饰、家电、美妆、珠宝、生活服务等众多顶尖零售品牌提供一站式新零售解决方案，助力企业向数字化转型升级！<br>
			  公司现有合作品牌600+、上线门店25万+、应用导购125万+、服务会员2亿+，代表客户有JNBY集团、安正集团、欣贺集团、GXG、雅莹、马克华菲、巴拉巴拉、地素、柒牌、乔丹、松下环境等。<br>
			  未来，达摩将持续深耕产品与服务，用新技术为零售行业赋能。</div>
          <div class="pr" style="width: 100%; height: 56px;">
            <div class="title2">达摩价值观</div>
            <!-- <div class="title3 tr">共赢</div> -->
						<img class="gongy-img" src="../static/images/gongy.png" />
          </div>
          <div class="hzgy">
            <div class="hzgy-c"><b>成就客户</b><!-- 诚实正直 身体力行 --></div>
            <div class="hzgy-c"><b>诚信负责</b><!-- 求责于己 勇于承担 --></div>
          </div>
					<div class="hzgy">
					  <div class="hzgy-c"><b>专业务实</b><!-- 不忘初心 精益求精 --></div>
					  <div class="hzgy-c"><b>协同创新</b><!-- 共享共担 团结一致 --></div>
					</div>
        </div>
        <div class="abg"></div>
			</div>
		</div>
    <div class="damo-zj pr section">
      <div class="steps-box">
				<div class="box">
				  <div class="about-title fw">达摩足迹</div>
				</div>
				<div :class="[abouttype ? 'step-bg-ons step-bg' : 'step-bg']">
          <div class="step-bg-da step-bg-dg" style="margin-right: 25px;">
            <p class="step-bg-top">2015年</p>
            <p class="step-bg-bottom">浙江达摩网络科技有限公司成立</p>
          </div>
          <div class="step-bg-da step-bg-db">
            <p class="step-bg-top">2016年</p>
            <p class="step-bg-bottom" style="padding-top: 17px;">核心产品GIC系统正式发布</p>
          </div>
          <div class="step-bg-da step-bg-dc">
            <p class="step-bg-top">2017年</p>
            <p class="step-bg-bottom">深圳、厦门、上海分公司成立<br>导购管理工具好办上线</p>
          </div>
          <div class="step-bg-da step-bg-dd">
            <p class="step-bg-top">2018年</p>
            <p class="step-bg-bottom" style="height: 119px;">合作品牌 200 余家，其中上市公司突破 10 家，牵手GXG集团、江南布衣等<br>北京、广州、江苏分公司成立<br>公司获得A轮融资</p>
          </div>
          <div class="step-bg-da step-bg-de">
            <p class="step-bg-top">2019年</p>
            <p class="step-bg-bottom" style="height: 60px;">举办首届UCO运营增长峰会<br>成立UCO学院</p>
          </div>
          <div class="step-bg-da step-bg-df">
            <p class="step-bg-top">2020年</p>
            <p class="step-bg-bottom">合作品牌突破 500 家，KA类客户快速增加，与安正时尚、地素时尚、巴拉巴拉等达成深度合作</p>
          </div>
        </div>
			</div>
    </div>
    <div class="box">
      <div class="about-title fw">联系我们</div>
      <div class="contact-box">
				<div class="contact-a">
					<img class="contact-img" src="../static/images/tel.png">
					<div class="contact-b">
						<p class="p01">电话</p>
						<p class="p02">400-888-9570</p>
					</div>
				</div>
				<div class="contact-a contact-c">
					<img class="contact-img" src="../static/images/addrc.png">
					<div class="contact-b">
						<p class="p01">地址</p>
						<p class="p02">杭州市余杭区欧美金融城EFC英国中心T2-28楼</p>
					</div>
				</div>
				<div class="contact-a">
					<img class="contact-img" src="../static/images/aemail.png">
					<div class="contact-b">
						<p class="p01">邮箱</p>
						<p class="p02">admin@demogic.com</p>
					</div>
				</div>
			</div>
    </div>
  	<damo-footer @footclick="handleFoot"/>
  </div>
</template>
<script>
	import DamoHeader from '@/components/DamoHeader.vue'
	import DamoFooter from '@/components/DamoFooter.vue'
	export default {
	  name: 'about',
    metaInfo: {
      title: '达摩网络-让信息连接一切，成就所有零售者',
      link: [
        { rel: 'protocol', href: 'https://ding-doc.dingtalk.com/doc#/faquestions/ftpfeu' }
      ]
    },
	  components: {
			DamoHeader,
	    DamoFooter
	  },
		data() {
			return {
				scroll: '',
				abouttype: false,
        selectId: null
			}
		},
		watch: {
		  scroll: function () {
		    this.loadSroll()
		  }
		},
		mounted() {
		  window.addEventListener('scroll', this.handleTabFix, true)
      this.selectId = localStorage.getItem("toId");
      if(this.selectId) {
        this.jump(this.selectId)
        setTimeout(() => {
          localStorage.setItem("toId",'')
        }, 300);
      } else {
        // console.log('wu')
      }
		},
		methods: {
      handlenav(_url) {
        window.open(_url, '_blank')
      },
			handleTabFix() {
			  this.scroll = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
			},
      jump(index) {
        var self = this;
        let jump = document.getElementsByClassName('section')
        // 获取需要滚动的距离
        let total = jump[index].offsetTop - 150
        // console.log(total)
        $('html, body').animate({ scrollTop: total }, { duration: 500,easing: "swing" })
        return false
      },
			loadSroll() {
			  var self = this
			  if(self.scroll > 500 && this.abouttype === false){
			    setTimeout(() => {
			    	this.abouttype = true
			    }, 300)
			  }
			},
			handleFoot(e) {
				this.jump(e)
			}
		},
		beforeRouteLeave (to, from, next) {
			window.removeEventListener('scroll', this.handleTabFix, true)
			next()
		}
	}
	</script>
	<style>
	.gongy-img{
		width: 67px;
		height: 67px;
		display: block;
		position: absolute;
		right: -10px;
		top: -10px;
	}
	.step-bg-dg{
		transition: opacity .6s ease-in;
		opacity: 0;
	}
	.step-bg-db{
		transition: opacity .6s ease-in .5s;
		opacity: 0;
	}
	.step-bg-dc{
		transition: opacity .6s ease-in 1s;
		opacity: 0;
	}
	.step-bg-dd{
		transition: opacity .6s ease-in 1.5s;
		opacity: 0;
	}
	.step-bg-de{
		transition: opacity .6s ease-in 2s;
		opacity: 0;
	}
	.step-bg-df{
		transition: opacity .6s ease-in 2.5s;
		opacity: 0;
	}
	.step-bg-ons .step-bg-da{
		opacity: 1;
	}
  .step-bg-da{
    width: 208px;
    height: 95px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.1);
    overflow: hidden;
    float: left;
  }
  .step-bg-dg{
	  position: absolute;
	  left: 85px;
  }
  .step-bg-db{
	position: absolute;
	left: 280px;
	top: 215px;
  }
  .step-bg-dc{
    position: absolute;
    left: 480px;
	width: 212px;
  }
  .step-bg-dc .step-bg-bottom{
	height: 80px;
    background-color: #179EFD;
  }
  .step-bg-dd{
	position: absolute;
	left: 693px;
	top: 215px;
    height: 155px;
    width: 216px;
  }
  .step-bg-dd .step-bg-bottom{
    background-color: #1966FE;
	padding-right: 5px;
  }
  .step-bg-de{
	position: absolute;
	left: 885px;
    height: 96px;
    width: 230px;
  }
  .step-bg-df{
	position: absolute;
	left: 1105px;
	top: 215px;
    height: 138px;
    width: 216px;
  }
  .step-bg-de .step-bg-bottom{
    background-color: #1A5BFD;
    padding-right: 0;
  }
  .step-bg-df .step-bg-bottom{
    background-color: #1A5BFD;
	height: 102px;
  }
  .step-bg-top{
    height: 36px;
    width: 100%;
    text-align: center;
    line-height: 22px;
    padding-top: 5px;
    box-sizing: border-box;
  }
  .step-bg-bottom{
    height: 59px;
    width: 100%;
    box-sizing: border-box;
    background: rgba(22,195,255,1);
    color: #FFFFFF;
    line-height: 20px;
    padding: 11px 15px 0;
    font-size: 14px;
  }
	.contact-box{
		margin: 65px auto 62px;
		overflow: hidden;
		padding-left: 115px;
	}
	.contact-a{
		float: left;
	}
	.contact-a .contact-img{
		display: block;
		float: left;
		margin-right: 18px;
		margin-top: 5px;
	}
	.contact-b {
		float: left;
	}
	.contact-b .p01{
		color: #8E9FC0;
		font-size: 14px;
		margin-bottom: 9px;
	}
	.contact-b .p02{
		color: #1C2E4A;
		font-size: 14px;
	}
	.contact-c{
		position: relative;
		padding: 0 50px 0 40px;
		margin: 0 110px 0 118px;
	}
	.contact-c:before{
		width: 1px;
		height: 40px;
		content: "";
		display: block;
		background-color: #E1E6EE;
		position: absolute;
		left: 0;
		top: 8px;
	}
	.contact-c:after{
		width: 1px;
		height: 40px;
		content: "";
		display: block;
		background-color: #E1E6EE;
		position: absolute;
		right: 0;
		top: 8px;
	}
	.step-bg{
		width: 1440px;
		height: 324px;
		background-image: url(../static/images/step_bg.png);
		background-position: left center;
		background-repeat: no-repeat;
		margin-top: 76px;
		box-sizing: border-box;
		position: relative;
	}
	.about-banner{
		padding-top: 90px;
		width: 100%;
		position: relative;
		z-index: 1;
	}
	.about-banner .p01{
		text-align: center;
		font-size: 28px;
		color: #fff;
	}
	.about-banner .p02{
		text-align: center;
		color: #fff;
		width: 130px;
		height: 40px;
		line-height: 40px;
		border: 1px solid #fff;
		margin: 40px auto 0;
		font-size: 18px;
		background-color: rgba(255, 255, 255, 0);
		transition: all 0.4s;
	}
  .about-banner .p02:hover{
    color: #023E99;
    background-color: #FFFFFF;
    cursor:pointer
  }
	.about-title{
	  font-size: 22px;
	  color: #1C2E4A;
	  position: relative;
	  line-height: 30px;
	  height: 30px;
	  padding-left: 42px;
	  box-sizing: border-box;
		width: 140px;
		margin: 0 auto;
	}
	.about-title:before{
	  content: "";
	  width: 19px;
	  height: 5px;
	  background: #023E99;
	  position: absolute;
	  top: 8px;
	  left: 0;
	  border-radius:5px;
	  display: block;
	}
	.about-title:after{
	  content: "";
	  width: 17px;
	  height: 5px;
	  background: #C2D7F6;
	  position: absolute;
	  top: 17px;
	  left: 10px;
	  border-radius:5px;
	  display: block;
	}
	.about-box{
		padding-top: 70px;
		width: 100%;
		height: 800px;
		overflow: hidden;
		min-width: 100%;
		position: relative;
		box-sizing: border-box;
	}
	.about-box-a{
		width: 100%;
		height: 100px;
		margin: 45px 0 0;
	}
	.about-box-a .diva, .about-box-a .divb, .about-box-a .divc{
		width:272px;
		height:96px;
		box-shadow:0px 2px 6px 3px rgba(205,214,242,0.25);
		float: left;
		padding-top: 21px;
		box-sizing: border-box;
	}
	.about-box-a .divb{
		margin: 0 192px;
	}
	.diva .img-a{
		float: left;
		margin: 12px 12px 0 20px;
	}
	.divb .img-a{
		float: left;
		margin: 12px 12px 0 36px;
	}
	.divc .img-a{
		float: left;
		margin: 12px 12px 0 14px;
	}
	.con-a{
		max-width: 200px;
	}
	.con-a .p01{
		font-size: 14px;
		font-weight: bold;
		color: #1C2E4A;
		line-height: 20px;
		margin-bottom: 9px;
	}
	.con-a .p02{
		font-size: 14px;
		color: #60708F;
		line-height: 20px;
	}
	.about-box-b{
		width: 1270px;
		height: 550px;
    background-image: url(../static/images/about_img.png);
    background-position: right center;
    background-repeat: no-repeat;
    position: absolute;
		top: 220px;
		left: 50%;
		margin-left: -600px;
	}
  .about-conl{
    width: 490px;
    padding-top: 85px;
  }
  .about-conl .title{
    font-size: 16px;
    color: #1C2E4A;
    width: 192px;
    background-image: url(../static/images/introduction.png);
    background-position: right top;
    background-repeat: no-repeat;
    padding-top: 10px;
    font-weight: bold;
  }
  .about-conl .title2{
    font-size: 16px;
    color: #1C2E4A;
    width: 130px;
    background-image: url(../static/images/avalue.png);
    background-position: right top;
    background-repeat: no-repeat;
    padding-top: 10px;
    font-weight: bold;
    float: left;
  }
  .title3{
    color: #023E99;
    font-size: 16px;
    position: relative;
    padding-top: 10px;
    float: right;
    width: 60px;
    font-weight: bold;
  }
  /* .title3:before{
    content: "";
    width:18px;
    height:16px;
    background:linear-gradient(90deg,rgba(108,166,255,0) 0%,rgba(108,166,255,0.28) 100%);
    display: block;
    position: absolute;
    left: 0;
    top: 13px;
    display: block;
    font-weight: bold;
  } */
  .about-conl .text{
    color: #60708F;
    font-size: 14px;
    line-height: 28px;
    padding: 20px 0 28px;
    margin-bottom: 17px;
    border-bottom: 1px solid #D3E0FD;
  }
  .hzgy{
    width: 100%;
    height: 30px;
  }
  .hzgy .hzgy-c {
    width: 25%;
    float: left;
    font-size: 16px;
    color: #60708F;
  }
  .hzgy .hzgy-c b{
    color: #023E99;
  }
  .damo-zj{
    background: #FAFCFF;
    margin-top: -10px;
		width: 100%;
		overflow: hidden;
		height: 555px;
		margin-bottom: 65px;
  }
  .steps-box{
    width: 1400px;
    margin-left: -700px;
    padding-top: 50px;
		position: absolute;
		top: 0;
		left: 50%;
  }
	</style>
